.search-result-item {
  padding: 5% 5%;
  border-radius: 5px;
}

.search-result-item:hover,
.search-result-item__hover {
  background-color: #b8b8b860;
  cursor: pointer;
  font-weight: bold;
}

@media only screen and (max-width: 1000px) {
  .search-result-item {
    padding: 2.5% 3.5%;
    border-radius: 0;
  }

  .search-result-item:not(:last-child) {
    border-bottom: 1px solid rgb(94, 94, 94);
  }
}
