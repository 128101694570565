.my-branch-flex-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.my-branch-card {
  width: 80vw !important;
  max-width: 385px;
  height: 50vh;
  margin: 5px 0;
  padding-bottom: 25px;
}

.my-branch-card img {
  object-fit: cover;
  height: 75%;
}
.my-branch-card-name {
  font-size: min(14px, 3vw) !important;
  margin-bottom: 2px !important;
  font-weight: bold;
}

.my-branch-card-title {
  font-size: min(14px, 3vw) !important;
}

.my-branch-button {
  background-color: #061f54 !important;
  margin: 0 auto !important;
  font-size: min(12px, 2vw) !important;
}

@media (min-width: 992px) {
  .my-branch-card {
    width: 20vw !important;
    max-width: 240px;
    height: 45vh;
    margin: 5px 0;
    padding-bottom: 50px;
  }

  .my-branch-card img {
    object-fit: cover;
    height: 80%;
  }

  .my-branch-card-name {
    font-size: min(12px, 0.9vw) !important;
  }
  .my-branch-card-title {
    font-size: min(12px, 0.9vw) !important;
  }

  .my-branch-button {
    background-color: #061f54 !important;
    margin: 0 auto !important;
    font-size: min(8px, 0.65vw) !important;
  }
}
