.impersonation-banner {
  position: relative;
  height: 60px;
  z-index: 10;
  background-color: #061f54;
  color: white;
  text-align: center;
  padding: 18px 20px 0;
  margin: 5px 15px 0 0;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.close-spin {
  position: absolute;
  right: -8px;
  top: -10px;
  width: 32px;
  height: 32px;
  opacity: 1 !important;
  background-color: #eeeeee;
  border-radius: 50%;
  border: solid 2px #2e2e2e;
  box-shadow: rgb(0 0 0 / 42%) -2px 1px 7px 1px;
}
.close-spin:hover {
  transform: scale(1.15, 1.15) rotateZ(90deg);
  transition-duration: 0.5s;
}
.close-spin:before,
.close-spin:after {
  position: absolute;
  top: 5px;
  right: 13px;
  content: " ";
  height: 18px;
  width: 2px;
  background-color: #2e2e2e;
  border-radius: 10%;
}
.close-spin:before {
  transform: rotate(45deg);
}
.close-spin:after {
  transform: rotate(-45deg);
}
