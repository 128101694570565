.search-results-container {
  position: absolute;
  background-color: white;
  color: black;
  border-radius: 5px;
  width: 12.5%;
  padding: 1% 0;
}

.fade-in {
  animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .search-results-container {
    z-index: 1;
    position: absolute;
    background-color: rgb(37, 37, 37);
    color: white;
    width: 80vw;
    border-radius: 5px;
    padding: 1% 0;
  }
}
