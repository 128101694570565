.backdrop {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.spinner-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fefefe;
  padding: 20px 0;
  margin: 15% auto; /* 15% from the top and centered */
  border: 1px solid #888;
  border-radius: 20px;
  width: 20%; /* Could be more or less, depending on screen size */
  height: 35%;
}

.spinner-title {
  text-align: center;
  max-width: 75%;
  color: rgb(41, 41, 41);
  font-size: 20px;
  margin-bottom: 25px;
}
