.login__background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  width: 40vw;
  margin-bottom: 1vh;
}

.login__card {
  width: 80vw;
  max-width: 800px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fefefa;
  border-radius: 10px;
}

.login__card-title {
  color: #1d1d1d;
  text-align: center;
  line-height: 1.2;
  font-size: 1.5rem;
  margin-bottom: 0px;
}
.login__card-quote {
  margin-top: 1vh;
  margin-bottom: 2vh;
  color: #313131;
  text-align: center;
  line-height: 1.2;
  font-size: 1.25rem;
  font-style: italic;
}

.login__card a {
  color: #f5f5f5;
  font-size: 1rem;
}

.login__google-button {
  width: 9vw;
  min-width: 175px;
  cursor: pointer;
  color: #fff;
}

.login__button-group {
  width: 75%;
  display: flex;
  justify-content: space-around;
}

body {
  background: linear-gradient(-45deg, #ffffff, #018bd4, #061f54, #061f54);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
